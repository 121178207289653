* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', sans-serif;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700 !important;
}

h4 {
  font-family: 'Lato', sans-serif;
  font-weight: 500 !important;
}

p {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 20px;
}

.homepage { 
  margin: 0 !important;
  background-image: url("../_constants/background.jpeg");
  box-shadow: inset 0 0 0 1000px rgba(40,40,40,.3);
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-width: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 !important;
  align-items: center;
  color: white
}

.myFace {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: block;
  margin: auto;
}

.homeheading {
  padding: 10px;
  background: rgba(0,0,0,0.5);
  border: 2px solid white;
  margin-bottom: 0px;
}

.cv-link {
  display: inline-block;
  margin-top: -10px;
  text-decoration: none;
  padding: 10px;
  background: white;
  color: black;
  filter: drop-shadow(0 0 0.75rem black);
  font-size: 20px;
  -webkit-transition: .5s all; 
  transition: .5s all;
}

.cv-link:hover {
  transform: scale(1.5); /* W3C */
  -webkit-transform: scale(1.2); /* Safari & Chrome */
  -moz-transform: scale(1.2); /* Firefox */
  -ms-transform: scale(1.2); /* Internet Explorer */
  -o-transform: scale(1.2);
  text-decoration: none;
  color: black;
  /* filter: drop-shadow(0 0 0.25rem white); */
}

nav.menu {
  background: transparent;
  
}

.backButton {
  margin: 10px;
  z-index: 99;
  font-size: 35px;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  background: rgba(40,40,40,.8);
  color: white;
  position: fixed;
}

.detailTable, tbody, tr {
  display: block;
  margin: auto;
}

.detailTable td {
  display: inline-block;
  margin: auto;
  text-align: center;
}

.menu li a {
  float: left;
  color: white;
}

.projectsRow {
  padding: 100px 0;
  max-height: 250px;
}

.projectLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectLink a {
  display: table;
  vertical-align: middle;
}

.projectLink a img {
  max-height: 100px;
}

.projectInfo {
  /* padding: 50px; */
}

.projectContent h4 {

}

.projectContent p { 
}

.projectLink {

}